import { GRIEVANCE_FIELDS } from './grievances'

export const OCCURRENCE_INSTANCE_FIELDS = `
  id
  type {
    id
    name
    points
    key
  }
  active
  date
  endDate
  occurrenceTypeId
  points
  ncnsPoints
  occurrenceTypeName
  occurrenceTypeKey
  minutesLateOrLeftEarly
  signedCounselingSheetUrl
  notes
  counselingIssued
  counselingDate
  counselingType
  counselingExpiredAt
  counselingExpiresOn
  createdAt
  ncnsCounselingIssued
  ncnsCounselingDate
  ncnsCounselingType
  ncnsCounselingExpiredAt
  ncnsCounselingExpiresOn
  ncnsSignedCounselingSheetUrl
  counselingApprovedAt
  approvalManager {
    id
    name
    email
  }
  approvalEmailSentAt
  costCenter {
    externalId
    name
    account {
      hiddenFeatures
    }
  }
  reportingManager {
    id
    name
    email
  }
  ncnsCounselingManager {
    id
    name
    email
  }
  counselingManager {
    id
    name
    email
  }
  status
  expiresAt
  expiresAtChangeReason
  expiresAtChangeDocumentUrl
  deletedAt
  deletedBy {
    id
    name
    email
  }
  deleteNotes
  deleteDocuments
  verificationDocumentUrl
  grievance {
    ${GRIEVANCE_FIELDS}
  }
  events {
    id
    date
    type
    user {
      id
      email
      name
    }
    notes
    data
  }
  trackOccurrenceInstances {
    id
    points
    counselingIssued
    counselingDate
    counselingType
    signedCounselingSheetUrl
    trackId
  }
`

export const OCCURRENCE_INSTANCE_QUERY = gql`
  query OccurrenceInstanceQuery($input: OccurrenceInstancePageInput) {
    occurrenceInstancePage(input: $input) {
      occurrences {
        ${OCCURRENCE_INSTANCE_FIELDS}
      }
      hasNextPage
      hasPreviousPage
    }
  }
`

export const CREATE_OCCURRENCE_INSTANCE_MUTATION = gql`
  mutation CreateOccurrenceInstance($input: CreateOccurrenceInstanceInput!) {
    createOccurrenceInstance(input: $input) {
      id
      trackOccurrenceInstances {
        id
        points
        counselingIssued
        counselingDate
        counselingType
        signedCounselingSheetUrl
        trackId
      }
    }
  }
`

export const UPDATE_OCCURRENCE_INSTANCE_MUTATION = gql`
  mutation UpdateOccurrenceInstance($input: UpdateOccurrenceInstanceInput!) {
    updateOccurrenceInstance(input: $input) {
      id
    }
  }
`

export const DELETE_OCCURRENCE_INSTANCE_MUTATION = gql`
  mutation DeleteOccurrenceInstance(
    $id: String!
    $notes: String
    $documents: [String!]
  ) {
    deleteOccurrenceInstance(id: $id, notes: $notes, documents: $documents) {
      id
    }
  }
`

export const OCCURRENCES_EXPORT_MUTATION = gql`
  mutation exportOccurrences($input: ExportOccurrencesInput!) {
    exportOccurrences(input: $input) {
      url
    }
  }
`

export const OCCURRENCES_IMPORT_MUTATION = gql`
  mutation importOccurrences($input: ImportOccurrencesInput!) {
    importOccurrences(input: $input) {
      success
    }
  }
`

export const OCCURRENCE_TYPE_QUERY = gql`
  query FindOccurrenceTypes($associateId: String) {
    occurrenceTypes(associateId: $associateId) {
      id
      name
      key
      points
    }
  }
`

export const APPROVE_OCCURRENCE_MUTATION = gql`
  mutation ApproveOccurrence($id: String!) {
    approveOccurrence(id: $id) {
      id
    }
  }
`

export const OCCURRENCE_EVENTS_QUERY = gql`
  query OccurrenceEventsQuery($occurrenceId: String!, $type: String) {
    occurrenceEvents(occurrenceId: $occurrenceId, type: $type) {
      id
      type
      date
      notes
      user {
        id
        email
        name
      }
      data
    }
  }
`
