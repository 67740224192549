import { COVERAGE_SHIFT_SETTING_FIELDS } from './coverageShifts'
import { POLICY_RULE_SET_FIELDS } from './policyRuleSets'

export const ACCOUNTS_QUERY = gql`
  query accountsQuery($input: AccountPageInput!) {
    accountPage(input: $input) {
      accounts {
        id
        organizationId
        name
        timezone
        admins {
          id
          email
          associate {
            name
          }
        }
        coverageShiftSettings {
          ${COVERAGE_SHIFT_SETTING_FIELDS}
        }
        stripeSubscriptionId
        stripePlanName
        directBillingEnabled
        externalBilling
        costCenterCount
        associateCount
        onboardedAssociateCount
        onboardingCompletedAt
        accessRequestSubmittedAt
        accessRequestApprovedAt
        test
        allowHistoricalWorkRuleViolations
        autoArchiveExcusedAbsencesDays
        occurrenceAddEditLevels
      }
      hasNextPage
      hasPreviousPage
    }
  }
`

export const ACCOUNT_JOB_TITLES_QUERY = gql`
  query AccountJobTitlesQuery($id: String!) {
    account(id: $id) {
      id
      jobTitles
      supervisorJobTitles
    }
  }
`

export const ACCESS_REQUESTS_QUERY = gql`
  query AccessRequestsQuery($input: AccountPageInput!) {
    accountPage(input: $input) {
      accounts {
        id
        name
        address {
          fullAddress
        }
        accessRequestSubmittedByEmail
        maxAssociateCount
      }
      hasNextPage
      hasPreviousPage
    }
  }
`

export const APPROVE_ACCOUNT_MUTATION = gql`
  mutation ApproveAccountMutation($id: String!, $couponId: String) {
    approveAccount(id: $id, couponId: $couponId) {
      id
    }
  }
`

export const CREATE_ACCOUNT_MUTATION = gql`
  mutation CreateAccountMutation($input: CreateAccountInput!) {
    createAccount(input: $input) {
      id
    }
  }
`

export const UPDATE_ACCOUNT_MUTATION = gql`
  mutation UpdateAccountMutation($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      id
      isUnion
      policyRuleSets {
        ${POLICY_RULE_SET_FIELDS}
      }
    }
  }
`

export const ACCOUNT_EDIT_QUERY = gql`
  query AccountEditQuery($id: String!) {
    account(id: $id) {
      id
      organizationId
      name
      timezone
      collectiveBargainingAgreement
      isUnion
      introductoryAssociatesAreUnionized
      wrvApprovalRoles
      wrvCreateRoles
      logoUrl
      test
      stripeSubscriptionId
      stripePlanName
      directBillingEnabled
      externalBilling
      addonsEnabled
      allowHistoricalWorkRuleViolations
      autoArchiveExcusedAbsencesDays
      supportedLanguages
      policyRuleSets {
        ${POLICY_RULE_SET_FIELDS}
      }
      address {
        id
        fullAddress
        address1
        city
        state
        zip
        googlePlaceId
      }
      admins {
        id
        email
        associate {
          id
          name
        }
      }
      selfReportedAbsencesPhoneEnabled
      selfReportedAbsencesWebEnabled
      selfReportedAbsencesPhoneNumber
      selfReportedAbsencesUnidentifiedNotificationsLevels
      selfReportedAbsencesUnidentifiedNotificationsUserIds
      selfReportedAbsenceDismissLevels
      selfReportedAbsenceAutoArchiveDays
      notifyLevel3UsersOfSelfReportedAbsences
      selfReportedAbsenceNotifyLevel3UserIds
      occurrenceCounselingRequiresApproval
      counselingBreaks {
        start
        end
      }
      occurrenceAddEditLevels
      occurrenceDeleteLevels
      wrvDeleteLevels
      supportOccurrenceVerificationDocument
      coverageShiftSettings {
        ${COVERAGE_SHIFT_SETTING_FIELDS}
      }
    }
  }
`

export const ACCOUNT_DELETE_MUTATION = gql`
  mutation AccountDeleteMutation($id: String!) {
    deleteAccount(id: $id) {
      id
    }
  }
`

export const ACCOUNT_VIEW_QUERY = gql`
  query AccountViewQuery($id: String!) {
    account(id: $id) {
      id
      organizationId
      name
      timezone
      logoUrl
      collectiveBargainingAgreement
      isUnion
      introductoryAssociatesAreUnionized
      associateCount
      maxAssociateCount
      costCenterCount
      test
      stripeSubscriptionId
      stripePlanName
      directBillingEnabled
      externalBilling
      addonsEnabled
      hasDirectBilling
      selfReportedAbsencesPhoneEnabled
      selfReportedAbsencesWebEnabled
      selfReportedAbsencesPhoneNumber
      allowHistoricalWorkRuleViolations
      autoArchiveExcusedAbsencesDays
      occurrenceCounselingRequiresApproval
      policyRuleSets {
        ${POLICY_RULE_SET_FIELDS}
      }
      coverageShiftSettings {
        ${COVERAGE_SHIFT_SETTING_FIELDS}
      }
      address {
        fullAddress
        address1
        city
        state
        zip
      }
      admins {
        id
        email
        associate {
          id
          name
        }
      }
      billingDetails {
        subscription {
          id
          status
          cancelAtPeriodEnd
          currentPeriodStart
          currentPeriodEnd
          plan {
            name
            interval
            intervalCount
            amount
          }
          addons {
            id
            name
            amount
          }
        }
        upcomingInvoice {
          id
          periodStart
          periodEnd
          hostedInvoiceUrl
          total
        }
        invoices {
          invoices {
            id
            periodStart
            periodEnd
            hostedInvoiceUrl
            total
          }
          hasNextPage
          hasPreviousPage
        }
        paymentMethod {
          id
          card {
            brand
            last4
            expMonth
            expYear
          }
          billingDetails {
            name
            email
            phone
            address {
              line1
              line2
              city
              state
              postalCode
              country
            }
          }
        }
      }
      counselingBreaks {
        start
        end
      }
    }
  }
`

export const ACCOUNT_BILLING_PORTAL_URL_QUERY = gql`
  query AccountBillingPortalUrlQuery($id: String!) {
    account(id: $id) {
      id
      billingPortalUrl
    }
  }
`

export const PLANS_QUERY = gql`
  query PlansQuery($id: String!) {
    account(id: $id) {
      id
      organizationId
      name
      associateCount
      maxAssociateCount
      onboardingExpectedAssociateCount
      test
      stripeSubscriptionId
      stripePlanName
      directBillingEnabled
      addonsEnabled
      hasDirectBilling
      externalBilling
      availableCoupon {
        id
        name
        percentOff
        amountOff
        duration
      }
      billingDetails {
        subscription {
          id
          status
          cancelAtPeriodEnd
          currentPeriodStart
          currentPeriodEnd
          plan {
            id
            name
            interval
            intervalCount
            amount
          }
          addons {
            id
            name
            amount
          }
        }
        paymentMethod {
          id
          card {
            brand
            last4
            expMonth
            expYear
          }
          billingDetails {
            name
            email
            phone
            address {
              line1
              line2
              city
              state
              postalCode
              country
            }
          }
        }
      }
      availablePlans {
        id
        name
        description
        price
        interval
        product {
          id
          name
          metadata {
            key
            value
          }
        }
        features {
          id
          name
          type
          value
        }
        addons {
          id
          key
          name
          description
          price
          usageUnitPrice
          usageLimit
          usagePriceId
          displayOrder
        }
      }
    }
  }
`

export const ACCOUNT_SUBSCRIBE = gql`
  mutation AccountSubscribeMutation(
    $accountId: String!
    $planId: String!
    $addons: [String!]
    $paymentMethod: String!
  ) {
    subscribe(
      accountId: $accountId
      planId: $planId
      addons: $addons
      paymentMethod: $paymentMethod
    ) {
      url
    }
  }
`

export const ACCOUNT_PLAN_COUPONS_QUERY = gql`
  query AccountPlanCouponsQuery($accountId: String!) {
    getPlanCoupons(accountId: $accountId) {
      id
      name
      percentOff
      amountOff
      duration
    }
  }
`

export const UNSUBSCRIBED_ACCOUNTS_QUERY = gql`
  query UnsubscribedAccountsBannerQuery {
    currentUser {
      unsubscribedAccounts {
        id
        name
      }
      accountOnboarding {
        id
        name
        onboardingCompletedAt
        externalBilling
        stripeSubscriptionId
        test
        costCenterCount
        associateCount
      }
    }
  }
`
