import { useEffect, useState } from 'react'

import styled from '@emotion/styled'
import {
  Badge,
  Frame,
  Loading,
  Navigation,
  ProgressBar,
  Text,
  TopBar,
  Stack,
  Icon,
  Modal,
  Button,
} from '@shopify/polaris'
import {
  LogOutMinor,
  SettingsMinor,
  CustomersMinor,
  FinancesMinor,
  PasskeyMinor,
  AnalyticsMinor,
  QuestionMarkMinor,
  CircleTickMinor,
  InviteMinor,
  PrintMinor,
  TransactionFeeDollarMajor,
  ArrowRightMinor,
  PlayMinor,
  BehaviorMajor,
  StarOutlineMinor,
  CalendarTimeMinor,
  ToolsMajor,
  SectionMajor,
} from '@shopify/polaris-icons'

import { navigate, routes, useLocation, useMatch } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'
import { Toaster } from '@redwoodjs/web/toast'

import { useAuth } from 'src/auth'
import { hasOccurrenceImport } from 'src/common/helpers/features'
import { NavigationSectionHeading } from 'src/components/shared'
import GATracker from 'src/components/shared/GATracker'
import { UNSUBSCRIBED_ACCOUNTS_QUERY } from 'src/graphql/accounts'
import { MANAGED_ASSOCIATES_QUERY } from 'src/graphql/associates'
import { APP_USER_QUERY } from 'src/graphql/users'
import { determineOnboardingSteps } from 'src/helpers/onboarding'
import { ROLES } from 'src/helpers/roles'
import { initials, userDisplayString } from 'src/helpers/strings'
import { getAssociatedAccountIdsByUser } from 'src/helpers/users'

import { usePermissions, PermissionsProvider } from '../../contexts/permissions'

import logo from './logo.png'
import logo_white from './logo_white.png'

const FrameBannerContainer = styled.div`
  @media (min-width: 768px) {
    .Polaris-Navigation {
      padding-top: var(--t-payment-banner-height);
    }
  }
  .Polaris-Frame__Content {
    padding-top: var(--t-payment-banner-height);
  }
`

const FrameContainer = styled.div``

const PaymentRequiredBanner = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
  background: var(--p-action-primary);
  position: fixed;
  z-index: 100;
  top: 56px;
  left: 0;
  right: 0;
  border-radius: 0;
  width: 100%;
  height: 44px;
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: var(--p-action-primary-hovered);
  }

  svg {
    fill: white;
    transform: translateX(-50%);
    animation: bounce 1.5s infinite;
  }

  @keyframes bounce {
    0% {
      left: 0;
    }
    50% {
      left: 10px;
    }
    100% {
      left: 0;
    }
  }
`

const AccountRow = styled.div`
  border-bottom: var(--p-border-divider);
  padding-bottom: 1rem;

  ${(props) =>
    props.lastRow &&
    `
    padding-bottom: 0.5rem;
    border-bottom: none;
  `};
`

const ImportProgressBarContainer = styled.div`
  display: block;
  position: fixed;
  z-index: 512;
  left: 50%;
  top: 4px;
  width: 220px;
  cursor: pointer;
  padding: 6px 8px;
  transform: translateX(-50%);
  text-align: center;
  border-radius: 6px;

  p {
    position: relative;
    top: -2px;
    font-size: 13px;
  }

  @media (min-width: 375px) {
    width: 250px;
  }

  &:hover {
    background-color: var(--p-background-hovered);
  }
`

const PaddedBottom = styled.div`
  height: 100%;
  padding-bottom: 24px;
`

const ExpiringOccurrencesBadge = () => {
  const QUERY = gql`
    query ExpiringOccurrencesBadgeQuery {
      currentUser {
        activeRequestedCounselingsCount
      }
    }
  `
  const { data } = useQuery(QUERY)

  const requestedCounselingsCount =
    data?.currentUser?.activeRequestedCounselingsCount || 0

  if (requestedCounselingsCount && requestedCounselingsCount > 0) {
    return (
      <Badge>
        {requestedCounselingsCount >= 50 ? '50+' : requestedCounselingsCount}
      </Badge>
    )
  } else {
    return ''
  }
}

const AssociateTransfersBadge = () => {
  const QUERY = gql`
    query AssociateTransfersBadgeQuery {
      currentUser {
        associateTransferRequestsCount
      }
    }
  `
  const { data } = useQuery(QUERY)

  const associateTransferRequestsCount =
    data?.currentUser?.associateTransferRequestsCount || 0

  if (associateTransferRequestsCount && associateTransferRequestsCount > 0) {
    return <Badge>{associateTransferRequestsCount}</Badge>
  } else {
    return ''
  }
}

const AssociateSelfReportedAbsencesBadge = () => {
  const QUERY = gql`
    query SelfReportedAbsencesBadgeQuery {
      currentUser {
        pendingSelfReportedAbsencesCount
      }
    }
  `
  const { data } = useQuery(QUERY)

  const count = data?.currentUser?.pendingSelfReportedAbsencesCount || 0

  if (count && count > 0) {
    return <Badge>{count}</Badge>
  } else {
    return ''
  }
}

const AssociateGrievancesBadge = () => {
  const QUERY = gql`
    query AssociateGrievancesBadgeQuery {
      currentUser {
        activeGrievancesCount
      }
    }
  `
  const { data } = useQuery(QUERY)

  const count = data?.currentUser?.activeGrievancesCount || 0

  if (count && count > 0) {
    return <Badge>{count}</Badge>
  } else {
    return ''
  }
}

const AssociateTerminationsBadge = () => {
  const QUERY = gql`
    query AssociateTerminationsBadgeQuery {
      currentUser {
        associateTerminationRequestsCount
      }
    }
  `
  const { data } = useQuery(QUERY)

  const associateTerminationRequestsCount =
    data?.currentUser?.associateTerminationRequestsCount || 0

  if (
    associateTerminationRequestsCount &&
    associateTerminationRequestsCount > 0
  ) {
    return <Badge>{associateTerminationRequestsCount}</Badge>
  } else {
    return ''
  }
}

const ImportBar = () => {
  const { hasRole } = usePermissions()

  const { data } = useQuery(MANAGED_ASSOCIATES_QUERY)

  const associatesCompleted = data?.managedAssociates?.importedAssociatesCount
  const associatesTotal = data?.managedAssociates?.totalAssociatesCount

  if (
    !associatesTotal ||
    associatesCompleted === associatesTotal ||
    hasRole('SUPERVISOR')
  ) {
    return null
  }

  return (
    <ImportProgressBarContainer
      onClick={() => {
        navigate(routes.associateOccurrences())
      }}
    >
      <Text color="subdued">
        {associatesCompleted} / {associatesTotal} associates set up
      </Text>
      <ProgressBar
        progress={parseInt((associatesCompleted / associatesTotal) * 100, 10)}
        animated={false}
      />
    </ImportProgressBarContainer>
  )
}

const MainLayout = ({ loading, user, children, unsubscribedAccounts }) => {
  const { logOut } = useAuth()

  const [showUserMenu, setShowUserMenu] = useState(false)
  const [demoMode, setDemoMode] = useState(false)
  const [showUnsubscribedAccountsModal, setShowUnsubscribedAccountsModal] =
    useState(false)
  const toggleShowUserMenu = () => setShowUserMenu(!showUserMenu)

  const { hasRole } = usePermissions()

  const associate = user?.associate

  const isDeveloper = hasRole && hasRole(ROLES.DEVELOPER)
  useEffect(() => {
    if (!isDeveloper && window.location.pathname.includes('/internal')) {
      navigate(routes.associates())
    }
  }, [isDeveloper])

  const showGrievances =
    (hasRole && hasRole([ROLES.ADMIN])) ||
    (user?.adminOnAccounts || []).some((account) =>
      account.policyRuleSets.some(
        (policyRuleSet) => policyRuleSet.grievanceTrackingEnabled
      )
    ) ||
    (user?.managedCostCenters || []).some((costCenter) =>
      costCenter.account.policyRuleSets.some(
        (policyRuleSet) => policyRuleSet.grievanceTrackingEnabled
      )
    ) ||
    (user?.adminOnCostCenters || []).some((costCenter) =>
      costCenter.account.policyRuleSets.some(
        (policyRuleSet) => policyRuleSet.grievanceTrackingEnabled
      )
    )

  const showCoverageShifts =
    (hasRole && hasRole([ROLES.ADMIN])) ||
    (user?.adminOnAccounts || []).some(
      (account) =>
        account.coverageShiftSettings?.enabled &&
        account.coverageShiftSettings?.createLevels.some((level) =>
          user.roles.includes(level)
        )
    ) ||
    (user?.managedCostCenters || []).some(
      (costCenter) =>
        costCenter.account.coverageShiftSettings?.enabled &&
        costCenter.account.coverageShiftSettings?.createLevels.some((level) =>
          user.roles.includes(level)
        )
    ) ||
    (user?.adminOnCostCenters || []).some(
      (costCenter) =>
        costCenter.account.coverageShiftSettings?.enabled &&
        costCenter.account.coverageShiftSettings?.createLevels.some((level) =>
          user.roles.includes(level)
        )
    )
  const showPendingOccurrences =
    (hasRole && hasRole([ROLES.DEVELOPER])) ||
    getAssociatedAccountIdsByUser(user).some((accountId) =>
      hasOccurrenceImport({ id: accountId })
    )

  const userMenuMarkup = !loading && (
    <TopBar.UserMenu
      actions={[
        {
          items: [
            {
              content: 'Profile settings',
              icon: SettingsMinor,
              onAction: () => {
                navigate(routes.settings())
              },
            },
            ...(hasRole([ROLES.DEVELOPER])
              ? [
                  {
                    content: demoMode
                      ? 'Disable demo mode'
                      : 'Enable demo mode',
                    icon: PlayMinor,
                    onAction: () => {
                      setDemoMode(!demoMode)
                    },
                  },
                ]
              : []),
            {
              content: 'Log out',
              icon: LogOutMinor,
              onAction: async () => {
                try {
                  await fetch(
                    `${process.env.API_URL}/clearLoginAsUser?organizationId=${
                      user.organizationId || ''
                    }`,
                    {
                      method: 'POST',
                      credentials: 'same-origin',
                    }
                  )
                } catch (error) {
                  console.error('Error clearing login', error)
                }
                logOut()
              },
            },
          ],
        },
      ]}
      name={demoMode ? 'TrackPath Admin' : userDisplayString(user)}
      detail={associate?.costCenter?.name}
      initials={demoMode ? 'TA' : initials(user)}
      open={showUserMenu}
      onToggle={toggleShowUserMenu}
    />
  )

  const associateSubNavigationItems = [
    {
      onClick: () => navigate(routes.associates()),
      label: 'Associates',
      selected:
        !useMatch('/associates/counseling').match &&
        (useMatch('/associates').match ||
          useMatch('/associates/{id}').match ||
          useMatch('/a/{id}').match ||
          useMatch('/a/{id}/occurrences/new').match ||
          useMatch('/a/{id}/occurrences/{occId}').match),
    },
  ]

  if (hasRole && hasRole([ROLES.MANAGER, ROLES.ADMIN, ROLES.ACCOUNT_ADMIN])) {
    associateSubNavigationItems.push({
      onClick: () => navigate(routes.associateCounseling()),
      label: 'Counseling',
      selected: useMatch('/associates/counseling').match,
      badge: <ExpiringOccurrencesBadge />,
    })
  }

  associateSubNavigationItems.push({
    onClick: () => navigate(routes.associateAbsences()),
    label: 'Absences',
    selected: useMatch('/associates/absences').match,
    badge: <AssociateSelfReportedAbsencesBadge />,
  })

  if (hasRole && hasRole([ROLES.MANAGER, ROLES.ADMIN, ROLES.ACCOUNT_ADMIN])) {
    associateSubNavigationItems.push(
      {
        onClick: () => navigate(routes.associateTransfers()),
        label: 'Transfer requests',
        selected: useMatch('/associates/transfers').match,
        badge: <AssociateTransfersBadge />,
      },
      {
        onClick: () => navigate(routes.associateTerminations()),
        label: 'Terminations',
        selected: useMatch('/associates/terminations').match,
        badge: <AssociateTerminationsBadge />,
      },
      ...(showGrievances
        ? [
            {
              onClick: () => navigate(routes.associateGrievances()),
              label: 'Grievances',
              selected: useMatch('/associates/grievances').match,
              badge: <AssociateGrievancesBadge />,
            },
          ]
        : []),
      ...(showPendingOccurrences
        ? [
            {
              onClick: () => navigate(routes.occurrenceApprovals()),
              label: 'Occurrences',
              selected: useMatch('/associates/occurrence_approvals').match,
            },
          ]
        : [])
    )
  }

  const navigationMarkup = (
    <Navigation location="/">
      <div>
        <Navigation.Section
          items={[
            {
              label: 'Associates',
              url: '#',
              selected:
                useMatch('/associates').match ||
                useMatch('/associates/{id}').match ||
                useMatch('/associates/counseling').match ||
                useMatch('/a/{id}').match ||
                useMatch('/a/{id}/occurrences/new').match ||
                useMatch('/a/{id}/occurrences/{occId}').match,
              icon: CustomersMinor,
              onClick: () => {
                navigate(routes.associates())
              },
              subNavigationItems: associateSubNavigationItems,
            },
            ...(showCoverageShifts
              ? [
                  {
                    onClick: () => navigate(routes.associateCoverageShifts()),
                    label: 'Cover shifts',
                    selected:
                      useMatch('/cover_shifts').match ||
                      useMatch('/cover_shifts/add').match ||
                      useMatch('/cover_shifts/edit').match ||
                      useMatch('/cover_shifts/{id}').match ||
                      useMatch('/cover_shifts/{id}/events').match,
                    icon: CalendarTimeMinor,
                  },
                ]
              : []),
            ...(hasRole && hasRole(['ADMIN', 'ACCOUNT_ADMIN'])
              ? [
                  {
                    label:
                      user.adminOnAccounts.length > 1 || hasRole('ADMIN')
                        ? 'Accounts'
                        : 'Account',
                    selected:
                      useMatch('/accounts').match ||
                      useMatch('/accounts/{id}').match ||
                      useMatch('/accounts/{id}/edit').match ||
                      useMatch('/accounts/{id}/policies/new').match ||
                      useMatch('/accounts/{id}/policies/{policyId}').match ||
                      useMatch('/cost_centers/{id}').match ||
                      useMatch('/accounts/{id}/onboarding').match ||
                      useMatch('/accounts/{id}/plan').match ||
                      useMatch('/accounts/{id}/cover_shifts').match,
                    icon: FinancesMinor,
                    onClick: () => {
                      if (user.adminOnAccounts.length > 1 || hasRole('ADMIN')) {
                        navigate(routes.accounts())
                      } else {
                        navigate(
                          routes.account({ id: user.adminOnAccounts[0].id })
                        )
                      }
                    },
                  },
                ]
              : []),
            ...(hasRole && hasRole(['ADMIN', 'ACCOUNT_ADMIN', 'MANAGER'])
              ? [
                  {
                    label: 'Users',
                    selected:
                      useMatch('/users').match || useMatch('/users/{id}').match,
                    icon: PasskeyMinor,
                    onClick: () => navigate(routes.users()),
                  },
                ]
              : []),
            ...(hasRole && hasRole(['MANAGER', 'ADMIN', 'ACCOUNT_ADMIN'])
              ? [
                  {
                    label: 'Reports',
                    selected:
                      useMatch('/reports').match ||
                      useMatch('/reports/{id}').match,
                    icon: AnalyticsMinor,
                    onClick: () => {
                      navigate(routes.reports())
                    },
                  },
                ]
              : []),
          ]}
        />
        {!demoMode && (isDeveloper || (hasRole && hasRole(['ADMIN']))) && (
          <Navigation.Section
            title={
              <NavigationSectionHeading>TrackPath</NavigationSectionHeading>
            }
            items={[
              {
                label: 'Usage stats',
                selected: useMatch('/usage').match,
                icon: BehaviorMajor,
                onClick: () => navigate(routes.usage()),
              },
            ]}
          />
        )}
        {isDeveloper && !demoMode && (
          <Navigation.Section
            title={
              <NavigationSectionHeading>Staff only</NavigationSectionHeading>
            }
            items={[
              {
                label: 'Internal tools',
                selected: useMatch('/internal').match,
                icon: ToolsMajor,
                onClick: () => {
                  navigate(routes.internalTools())
                },
              },
              {
                icon: SectionMajor,
                onClick: () => navigate(routes.modules()),
                label: 'Modules',
                selected:
                  useMatch('/internal/modules').match ||
                  useMatch('/internal/modules/{id}').match,
              },
              {
                label: 'Access requests',
                selected: useMatch('/access-requests').match,
                icon: TransactionFeeDollarMajor,
                onClick: () => navigate(routes.accessRequests()),
              },
              {
                label: 'Associate setup',
                selected:
                  useMatch('/onboarding').match ||
                  useMatch('/onboarding/occurrences').match,
                icon: CircleTickMinor,
                onClick: () => navigate(routes.onboarding()),
              },
              {
                label: 'Email',
                selected:
                  useMatch('/email').match || useMatch('/email/{id}').match,
                icon: InviteMinor,
                onClick: () => navigate(routes.email()),
              },
              {
                label: 'Print',
                selected: useMatch('/print').match,
                icon: PrintMinor,
                onClick: () => navigate(routes.print()),
              },
              {
                label: 'Icons',
                selected: useMatch('/icons').match,
                icon: StarOutlineMinor,
                onClick: () => navigate(routes.icons()),
              },
            ]}
          />
        )}
      </div>
      <Navigation.Section
        title={<NavigationSectionHeading>Need help?</NavigationSectionHeading>}
        items={[
          {
            label: 'Support',
            selected:
              useMatch('/support').match || useMatch('/support/{handle}').match,
            icon: QuestionMarkMinor,
            onClick: () => navigate(routes.support()),
          },
        ]}
      />
    </Navigation>
  )

  const _logo = {
    width: 131,
    topBarSource: logo,
    contextualSaveBarSource: logo_white,
    url: '/',
    accessibilityLabel: 'TrackPath',
  }

  const [showMobileNavigation, setShowMobileNavigation] = useState(false)
  const hasUnsubscribedAccounts = unsubscribedAccounts.length > 0
  const isOnSelectPlanPage =
    useMatch('/accounts/{id}/plan').match ||
    useMatch('/accounts/{id}/select_plan').match
  const showPaymentBanner = hasUnsubscribedAccounts && !isOnSelectPlanPage
  const Container = showPaymentBanner ? FrameBannerContainer : FrameContainer
  const paymentBannerTitle =
    unsubscribedAccounts.length === 1
      ? 'One account requires a paid subscription'
      : `${unsubscribedAccounts.length} accounts require paid subscriptions`

  return (
    <Container>
      <GATracker />
      <Frame
        topBar={
          <TopBar
            showNavigationToggle
            onNavigationToggle={() =>
              setShowMobileNavigation(!showMobileNavigation)
            }
            userMenu={userMenuMarkup}
          />
        }
        navigation={navigationMarkup}
        logo={_logo}
        showMobileNavigation={showMobileNavigation}
        onNavigationDismiss={() => setShowMobileNavigation(false)}
      >
        {!demoMode && <ImportBar />}
        {loading && <Loading />}
        <Toaster toastOptions={{ className: 'rw-toast', duration: 6000 }} />
        <PaddedBottom>{children}</PaddedBottom>
      </Frame>
      {showPaymentBanner && (
        <>
          <PaymentRequiredBanner
            onClick={() => {
              if (unsubscribedAccounts.length === 1) {
                navigate(routes.selectPlan({ id: unsubscribedAccounts[0].id }))
              } else {
                setShowUnsubscribedAccountsModal(true)
              }
            }}
          >
            <Stack>
              <span>{paymentBannerTitle}</span>
              <Icon source={ArrowRightMinor} />
            </Stack>
          </PaymentRequiredBanner>
          {showUnsubscribedAccountsModal && (
            <Modal
              open
              title={paymentBannerTitle}
              onClose={() => setShowUnsubscribedAccountsModal(false)}
            >
              <Modal.Section>
                <Stack vertical justifyContent="center">
                  {unsubscribedAccounts.map((account, index) => {
                    return (
                      <AccountRow
                        key={account.id}
                        lastRow={index === unsubscribedAccounts.length - 1}
                      >
                        <Stack wrap={false} alignment="center">
                          <Stack.Item fill>{account.name}</Stack.Item>
                          <Button
                            primary
                            onClick={() => {
                              navigate(routes.selectPlan({ id: account.id }))
                              setShowUnsubscribedAccountsModal(false)
                            }}
                          >
                            Select plan
                          </Button>
                        </Stack>
                      </AccountRow>
                    )
                  })}
                </Stack>
              </Modal.Section>
            </Modal>
          )}
        </>
      )}
    </Container>
  )
}

const MainLayoutContainer = ({ skeleton, children }) => {
  const { loading, data } = useQuery(APP_USER_QUERY, {
    onError: (error) => {
      console.log(`Access error: `, error)
      if (error.message === 'Not authorized') {
        // navigate(routes.login())
      }
    },
  })
  const location = useLocation()
  const { data: unsubscribedAccountsData } = useQuery(
    UNSUBSCRIBED_ACCOUNTS_QUERY
  )
  const unsubscribedAccounts =
    unsubscribedAccountsData?.currentUser?.unsubscribedAccounts || []

  const user = data?.currentUser

  useEffect(() => {
    if (user && unsubscribedAccountsData?.currentUser) {
      const onboardingSteps = determineOnboardingSteps({
        user,
        accountId: null,
        accounts: unsubscribedAccountsData?.currentUser?.accountOnboarding,
        location,
        routes,
      })
      const nextIncompleteStep = onboardingSteps?.nextIncompleteStep
      if (nextIncompleteStep && nextIncompleteStep.id !== 'select-plan') {
        navigate(nextIncompleteStep.route)
      }
    }
  }, [user, unsubscribedAccountsData])

  return (
    <PermissionsProvider currentUser={user}>
      <MainLayout
        loading={loading || skeleton}
        user={user}
        unsubscribedAccounts={unsubscribedAccounts}
      >
        {children}
      </MainLayout>
    </PermissionsProvider>
  )
}

export default MainLayoutContainer
